export const ROUTE_NAMES = Object.freeze({
  documents: {
    paymentRequirementsClosed: 'payment-requirements.closed',

    cardIndexesFirstIndex: 'card-indexes-first.index',
    cardIndexesFirstDetails: 'card-indexes-first.details',
    closedCardIndexesFirstDetails: 'closed-card-indexes-first.details',

    cardIndexesSecondIndex: 'card-indexes-second.index',
    cardIndexesSecondDetails: 'card-indexes-second.details',
    closedCardIndexesSecondDetails: 'closed-card-indexes-second.details'
  }
});